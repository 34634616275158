import React, { useState } from 'react';
import image1 from '../../images/im1.jpg';
import image3 from '../../images/im2.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './Section1.css';

const images = [image1, image3];

const ImageSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState('');

  const nextImage = () => {
    setDirection('slide-left');
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setDirection('slide-right');
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="image-section">
      <div className="image-container">
        <img
          src={images[currentIndex]}
          alt="carousel"
          className={`image-display ${direction}`}
          onAnimationEnd={() => setDirection('')} 
        />
        <button onClick={prevImage} className="arrow-btn left-arrow">
          <FontAwesomeIcon icon={faArrowLeft} /> 
        </button>
        <button onClick={nextImage} className="arrow-btn right-arrow">
           <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
};

export default ImageSection;
