import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import './Price.css'

const Pricing = () => {
  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">Pricing Plans</h2>
      <div className="row justify-content-center">
        
        {/* Monthly Subscription Card */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Monthly Plan</h5>
              <p className="display-4 text-primary">12000<span className="fs-6">/ month</span></p>
              <p className="text-muted">One-Time Setup Fee: 15000</p>
              <ul className="list-unstyled">
                <li><FontAwesomeIcon className="checked-icon" icon={faCircleCheck}/> Access to all features</li>
                <li><FontAwesomeIcon className="checked-icon" icon={faCircleCheck}/> Regular updates and support</li>
                <li><FontAwesomeIcon className="checked-icon" icon={faCircleCheck}/> Unlimited patient registration</li>
                <li><FontAwesomeIcon className="checked-icon" icon={faCircleCheck}/> Unlimited report generation and billing</li>
                <li><FontAwesomeIcon className="checked-icon" icon={faCircleCheck}/> Full access to built-in templates and role-based login</li>

              </ul>
              {/* <button className="btn btn-primary mt-3">Subscribe Monthly</button> */}
            </div>
          </div>
        </div>

        {/* Quarterly Subscription Card */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Per Lab Order</h5>
              <p className="display-4 text-primary">80 PKR<span className="fs-6"> / order</span></p>
              <p className="text-muted">One-Time Setup Fee: 15000</p>
              <ul className="list-unstyled">
              <li><FontAwesomeIcon className="checked-icon" icon={faCircleCheck}/> Access to all features</li>
                <li><FontAwesomeIcon className="checked-icon" icon={faCircleCheck}/> Regular updates and support</li>
                <li><FontAwesomeIcon className="checked-icon" icon={faCircleCheck}/> Unlimited patient registration</li>
                <li><FontAwesomeIcon className="checked-icon" icon={faCircleCheck}/> Unlimited report generation and billing</li>
                <li><FontAwesomeIcon className="checked-icon" icon={faCircleCheck}/> Full access to built-in templates and role-based login</li>

              </ul>
              {/* <button className="btn btn-primary mt-3">Subscribe Quarterly</button> */}
            </div>
          </div>
        </div>

        {/* Yearly Subscription Card */}
        {/* <div className="col-md-4 mb-4">
          <div className="card h-100 text-center shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Yearly Plan</h5>
              <p className="display-4 text-primary">$299<span className="fs-6">/ year</span></p>
              <p className="text-muted">One-Time Setup Fee: $100</p>
              <ul className="list-unstyled">
                <li>Access to all features</li>
                <li>Priority support</li>
                <li>Cancel anytime</li>
              </ul>
              <button className="btn btn-primary mt-3">Subscribe Yearly</button>
            </div>
          </div>
        </div> */}

      </div>
    </div>
  );
};

export default Pricing;
