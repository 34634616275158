import logo from './logo.svg';
import './App.css';
import Header from './Pages/Header/Header';


function App() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth'});
    }
  };
  return (
    <>
     <div className='main-div'>
       <Header></Header>
     </div>
    </>
  );
}

export default App;
