import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import html2pdf from "html2pdf.js";
import './TermConditions.css';

const TermsConditions = () => {
  const handleDownloadPDF = () => {
    const element = document.getElementById("terms-container");
    html2pdf()
      .from(element)
      .set({
        margin: 1,
        filename: "Terms_and_Conditions.pdf",
        html2canvas: { scale: 2 },
        jsPDF: { orientation: "portrait" }
      })
      .save();
  };
  return (
   
    <div className="terms-container container my-4 p-4  rounded shadow-sm">
      <div id="terms-container" className=" container my-4 p-4 "> 
      <h4 className="text-center mb-4">Terms and Conditions</h4>
      <section className="terms-section">
        <h4 className="font-size-12">Overview</h4>
        <p>
          These Terms and Conditions outline the rules and regulations for using our services. By subscribing to or purchasing our services, you agree to these terms. Please read them carefully.
        </p>
      </section>
      
      <section className="terms-section">
        <h4 className="font-size-12">Pricing Structure</h4>
        <p>Our pricing consists of two types:</p>
        <ul>
          <li><strong className="font-size-12">One-Time Fee:</strong> This is a single payment required for setup, implementation, or customization services.</li>
          <li><strong className="font-size-12">Monthly Subscription:</strong> This is a recurring fee for continued access to our services and support.</li>
        </ul>
      </section>
      
      <section className="terms-section">
        <h4 className="font-size-12">One-Time Fees</h4>
        <p>
          Our one-time fee covers the initial setup and configuration of the services to meet your requirements. This fee is non-refundable and is paid upfront.
        </p>
      </section>
      
      <section className="terms-section">
        <h4 className="font-size-12">Monthly Subscription Fees</h4>
        <p>
          The monthly subscription fee grants you continued access to the service. This fee will be charged every month on your billing date and covers regular maintenance, updates, and customer support.
        </p>
        <p><strong>Cancellation Policy:</strong> You may cancel your subscription at any time, but refunds will not be provided for partial months.</p>
      </section>
      
      <section className="terms-section">
        <h4 className="font-size-12">Payment Terms</h4>
        <p>
          All fees are due on their respective billing dates. Failure to make timely payments may result in suspension or termination of your services.
        </p>
      </section>
      
      <section className="terms-section">
        <h4 className="font-size-12">Refund Policy</h4>
        <p>
          Refunds are generally not provided for the one-time fee or monthly subscriptions. However, in cases of technical issues or special circumstances, partial refunds may be considered.
        </p>
      </section>
      
      <section className="terms-section">
        <h4 className="font-size-12">Modifications to Terms</h4>
        <p>
          We reserve the right to modify these terms at any time. Updated terms will be communicated to users, and continued use of the service constitutes acceptance of any changes.
        </p>
      </section>
      <footer className="text-center mt-4">
        <p>&copy; {new Date().getFullYear()} Sehha Solutions Private Limited. All rights reserved.</p>
      </footer>
      </div>
      <button onClick={handleDownloadPDF} className="btn btn-primary mt-3">Download as PDF</button>
    </div>
    
  
  );
};

export default TermsConditions;
