import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Introduction = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-12 text-center">
          <h1 className="display-4 fw-bold">Welcome to Sehha Solutions</h1>
          <p className="lead mt-3">
            Sehha Solutions is a comprehensive cloud-based lab management system that empowers laboratories to streamline their workflows and enhance service delivery. 
          </p>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-6">
          <h3 className="fw-semibold">Advanced Lab Management System</h3>
          <p>
            Our platform is built to support labs in managing and processing tests with ease, allowing for seamless operations from test orders to results. It comes with a variety of built-in tests and options for customization to fit your lab's unique needs.
          </p>
        </div>
        <div className="col-md-6">
          <h3 className="fw-semibold">Customizable Reporting</h3>
          <p>
            Generate detailed, customizable reports that meet the specific requirements of your practice. Our system enables you to tailor reports for different test types, patient demographics, and more, helping you provide precise, reliable results.
          </p>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center">
          <p className="text-muted">
            Designed with mobile responsiveness, our platform allows access from any device, ensuring you stay connected and productive wherever you are.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Introduction;
