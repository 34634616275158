import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faFileInvoice, faStethoscope, faChartLine,faChartColumn, faFileAlt, faLock, faQrcode } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Features.css';

const featuresList = [
  { title: "Patient Registration", description: "Manage patient records and registrations easily.", icon: faUser },
  { title: "Billing Invoice Generation", description: "Generate detailed invoices for all patient services.", icon: faFileInvoice },
  { title: "Doctor Referral", description: "Refer patients to specialists and manage referrals.", icon: faStethoscope },
  { title: "Daily Business Reports",  description: "Get insights into your business’s daily performance with detailed data analysis and trends.", 
    icon: faChartLine 
  },
  { title: "Monthly Business Reports", description: "Analyze monthly trends to identify growth areas and optimize your business strategy.", 
    icon: faChartColumn 
  },
  {  title: "Yearly Business Reports",  description: "Review yearly summaries to assess long-term progress and make informed business decisions.", 
    icon: faChartColumn 
  },
  { title: "Built-in Templates", description: "Use predefined templates for quick and professional reports.", icon: faFileAlt },
  { title: "Role-Based Login", description: "Ensure secure access with role-based permissions.", icon: faLock },
  { title: "Patient Bar Code", description: "Generate barcodes for efficient patient identification.", icon: faQrcode },
];

const Features = () => {
  return (
    <div className="features-section container mt-4">
      <h2 className="text-center mb-5">Our Features</h2>
      <div className="row">
        {featuresList.map((feature, index) => (
          <div key={index} className="col-md-4 mb-5">
            <div className="card feature-card h-100 shadow">
              <div className="card-body text-center">
                <div className="icon-container mb-3">
                  <FontAwesomeIcon icon={feature.icon} size="3x" />
                </div>
                <h5 className="card-title mt-5">{feature.title}</h5>
                <p className="card-text">{feature.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
