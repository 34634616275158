// Header.js
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './Header.css';
import Logo from '../../images/Logo.png';
import ImageSection from '../ImageSection/ImageSection';
import Features from "../Features/Features";
import TermsConditions from "../Term&Conditions/TermConditions";
import Pricing from "../Pricing/pricing ";
import { useEffect,useState } from 'react';
import Introduction from "../introduction/introduction";


const Header = () => {
    const [showButton, setShowButton] = useState(false);
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }; const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 300); // Show button after scrolling down 300px
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src={Logo} alt="Logo" className="image-div" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a href="#" onClick={() => scrollToSection('ImageSection')}>Home</a>
              </li>
              <li className="nav-item">
                <a  href="#" onClick={() => scrollToSection('Features')}>Features</a>
              </li>
              <li className="nav-item">
                <a  href="#" onClick={() => scrollToSection('TermConditions')}>Terms & Conditions</a>
              </li>
              <li className="nav-item">
                <a  href="#" onClick={() => scrollToSection('Pricing')}>Pricing</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Content Sections */}
      <div>
        <div id="ImageSection">
          {/* <ImageSection /> */}
          <Introduction />
        </div>
        <div id="Features">
          <Features />
        </div>
        <div id="TermConditions">
          <TermsConditions />
        </div>
        <div id="Pricing">
          <Pricing />
        </div>
        {/* Back to Top Button */}
       {showButton && (
        <button className="back-to-top" onClick={scrollToTop}>
          ↑ Back to Top
        </button>
      )}
      </div>

       
    </>
  );
};

export default Header;
